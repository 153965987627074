import React from 'react';
import Layout from '../components/layout'; 
import SEO from '../components/seo';
import Card from '../components/card';
import Button from '../components/atoms/Button';
import { ExternalLink } from 'react-feather';

export default function Financing({ props }) {
  return (
    <Layout pageProps={props}>
      <SEO
        title={'Home Improvement Financing in Grand Rapids, Michigan'}
        description={
          'Roach Home Improvement offers flexible financing options for your home renovation projects in Grand Rapids, Michigan.'
        }
      />

      <section className="section bg-neutral-50 flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
        <div class="md:w-2/3 md:pr-4">
          <h1 class="text-2xl md:text-3xl font-bold text-gray-900">Home Improvement Financing</h1>
          <p class="mt-3 text-lg text-gray-700">
            At Roach Home Improvement, we're dedicated to making your home renovation dreams a reality in Grand Rapids,
            Michigan. We've partnered with reputable financial institutions to offer flexible financing options that
            suit your needs.
          </p>

          <div class="mt-5">
            <h2 class="text-xl md:text-2xl font-semibold text-gray-800">Synchrony</h2>
            <p class="mt-3 text-sm text-gray-600">
              Synchrony and Roach Home Improvement. We work together to help you afford what matters to you.
            </p>
            <ul class="list-disc list-inside bg-white shadow-md rounded-xl p-6 mt-4 text-gray-700">
              <img
                src={'/assets/synchrony.png'}
                alt="Ally Bank"
                className="mb-2 block h-12"
              />
              <li>Long-Term Financing: Secure financing for up to 20 years.</li>
              <li>Instant Approvals: Receive initial full approvals in just seconds.</li>
              <li>No Upfront Costs: Benefit from no origination fees or closing costs.</li>
              <li>Soft Credit Inquiry: Only a soft credit pull is required until funding.</li>
              <li>Deferred Payments: Start your repayments two months after your project is completed.</li>
              <div className="mt-4 flex">
                <Button
                  color="outline"
                  href={'https://lending.ally.com/qualify/6035/23374'}
                  target="_blank"
                  className="inline-block"
                >
                  Apply for financing <ExternalLink size={14} className="ml-2" />
                </Button>
              </div>
            </ul>
          </div>

          <div class="mt-8">
            <h2 class="text-xl md:text-2xl font-semibold text-gray-800">Michigan Saves Financing</h2>
            <ul class="list-disc list-inside bg-white shadow-md rounded-xl p-6 mt-4 text-gray-700">
              <img
                src={'/assets/cropped-michigan-saves-logo-1.png'}
                alt="Michigan Saves Financing"
                className="mb-2 block h-12"
              />
              <li>Quick and Easy Approvals: Get approved swiftly for loans up to $100,000 for up to 15 years.</li>
              <li>No Hidden Fees: Enjoy zero origination fees or closing costs.</li>
              <li>Flexible Repayment: No prepayment penalties. Pay off your loan at any time without extra charges.</li>
              <li>Secure and Simple: Sign your loan documents electronically.</li>
              <li>Tailored Terms: Choose a loan term that fits your budget perfectly.</li>
              <div className="mt-4 flex">
                <Button color="outline" href={'https://michigansaves.org/'} target="_blank" className="inline-block">
                  Learn more <ExternalLink size={14} className="ml-2" />
                </Button>
              </div>
            </ul>
          </div>
        </div>
        <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
          <Card sidebar={{ content: { content: '{{ContactInfo}}' } }} />
          <Card sidebar={{ content: { content: '{{WhyUs}}' } }} />
        </div>
      </section>
    </Layout>
  );
}
